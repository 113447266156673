import React from 'react'

const Query1 = () => {
  return (
    <iframe
      id="query1"
      width="100%"
      height="900"
      src="https://wizzard.nostalgic-kare.49-12-192-32.plesk.page"
      title="Query"
    ></iframe>
  )
}

export default Query1
